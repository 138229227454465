@import '../../../../scss/theme-bootstrap';

.email-signup-panel {
  &__wrapper {
    display: flex;
    max-width: 310px;
    color: $color-medium-dark-gray;
  }
  &__content-wrapper {
    flex: auto;
  }
  &__image {
    @include swap_direction(margin, 0 20px 0 0);
    max-width: 50%;
  }
  p {
    margin: 0;
    letter-spacing: -0.03em;
  }
  &__title {
    text-align: $ldirection;
    font-weight: 600;
    font-family: $font--lamer-text;
    font-size: 40px;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  &__description,
  &__sms-terms-condition {
    position: relative;
    text-align: $ldirection;
    font-size: 15px;
    margin-bottom: 30px;
  }
  input.email-signup-panel__field {
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-silver;
    border-radius: 0;
    padding: 0;
    margin-bottom: 40px;
    color: $color-medium-dark-gray;
    &:focus {
      border-bottom: 1px solid $color-medium-dark-gray;
      @include outline-none;
    }
    &::placeholder {
      text-transform: none;
      font-size: 15px;
    }
    &.error {
      color: $color-error;
    }
  }
  input[type='submit'] {
    height: 60px;
    text-transform: none;
    margin: auto;
    margin-bottom: 1em;
    width: 100%;
    border-radius: 0;
    font-size: 15px;
  }
  &__information {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: none;
  }
  &__icon--information {
    float: $ldirection;
    width: 20px;
    height: 20px;
    margin-#{$rdirection}: 20px;
  }
  &__sms-terms-conditions {
    label {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      @include swap_direction(padding, 0 0 40px 30px);
      position: relative;
      cursor: default;
      &::before {
        background-color: $white;
        border: 1px solid $color-light-gray;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        #{$ldirection}: 0;
        position: absolute;
        width: 20px;
      }
    }
    input[type='checkbox'] {
      visibility: hidden;
      & ~ label {
        &:before {
          content: '';
          margin: 0;
        }
      }
      &:checked {
        & + label {
          &:after {
            border-#{$rdirection}: 1px solid $white;
            border-bottom: 1px solid $white;
            content: ' ';
            display: inline-block;
            position: absolute;
            top: 4px;
            #{$ldirection}: 7px;
            transform: rotate(35deg);
            height: 9px;
            width: 5px;
          }
        }
        & ~ label {
          &:before {
            background-color: $color-medium-dark-gray;
            border-color: $color-medium-dark-gray;
            content: '';
            margin: 0;
          }
        }
      }
    }
  }
  &__gdpr-overlay {
    display: none;
    position: absolute;
    text-decoration: none;
    padding: 15px;
    background-color: $color-black;
    color: $color-white;
    top: 40px;
    width: 100%;
    z-index: 2;
    align-items: flex-start;
    justify-content: space-between;
    @include breakpoint($landscape-up) {
      line-height: normal;
      #{$ldirection}: -73%;
      top: 0;
      width: 71%;
    }
    &.expanded {
      display: flex;
    }
  }
  &__gdpr-link {
    position: relative;
    margin-bottom: 1em;
  }
  &__gdpr-overlay-icon--close {
    fill: $color-white;
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
  }
  &__gdpr-overlay-close-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    order: 2;
  }
  &__gdpr-overlay-copy {
    order: 1;
  }
  &__gdpr-link-copy {
    &:hover ~ .email-signup-panel__gdpr-overlay {
      display: flex;
    }
  }
  &__learn-more {
    text-align: center;
    margin: 0 auto 20px;
  }
  &__message--error {
    color: $color-error;
  }
  .privacy-policy-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    color: $color-grey;
    &:hover {
      .privacy-policy-tooltip-text {
        visibility: visible;
        opacity: 1;
      }
    }
    &-text {
      position: absolute;
      bottom: 100%;
      #{$ldirection}: -100%;
      width: 310px;
      max-width: calc(100vw - 40px);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s;
      padding: 10px;
      background-color: $white;
      border: 1px solid $color-light-grey;
      font-size: 12px;
      z-index: 2;
      .privacy-policy-tooltip-text-arrow {
        position: absolute;
        top: 100%;
        border-width: 5px;
        border-style: solid;
        border-color: $color-medium-gray transparent transparent transparent;
      }
    }
  }
}
